<template>
  <div class="row">
    <div class="col-12 card card-custom">
      <div class="card-body py-0 border-bottom">
        <ul class="nav nav-tabs nav-tabs-line border-bottom-0" role="tablist">
          <li class="nav-item mr-14 mt-4">
            <router-link :to="{ name: '/sunbeds/view/:guid/settings/sunbed' }" class="nav-link font-weight-bold border-2 pb-6 mx-0" data-toggle="tab" active-class="active">
              <span class="nav-icon">
                <i>
                  <font-awesome-icon :icon="['fas', 'tag']" />
                </i>
              </span>
              <span class="nav-text">Sunbed</span>
            </router-link>
          </li>

          <li v-if="$auth.hasPermission('equipment.device.sound') && $auth.hasPermission('internet_radio_stations.get')" class="nav-item mr-14 mt-4">
            <router-link :to="{ name: '/sunbeds/view/:guid/settings/radio' }" class="nav-link font-weight-bold border-2 pb-6 mx-0" data-toggle="tab" active-class="active">
              <span class="nav-icon">
                <i>
                  <font-awesome-icon :icon="['fas', 'music']" />
                </i>
              </span>
              <span class="nav-text">Online radio</span>
            </router-link>
          </li>

          <li class="nav-item mr-14 mt-4">
            <router-link :to="{ name: '/sunbeds/view/:guid/settings/management' }" class="nav-link font-weight-bold border-2 pb-6 mx-0" data-toggle="tab" active-class="active">
              <span class="nav-icon">
                <i>
                  <font-awesome-icon :icon="['fas', 'tasks-alt']" />
                </i>
              </span>
              <span class="nav-text">Management</span>
            </router-link>
          </li>

          <li v-if="$auth.isResourceFromIndex('users') && ($auth.hasPermission('companies.equipment.link') || $auth.hasPermission('companies.equipment.remove'))" class="nav-item mr-14 mt-4">
            <router-link :to="{ name: '/sunbeds/view/:guid/settings/link-company' }" class="nav-link font-weight-bold border-2 pb-6 mx-0" data-toggle="tab" active-class="active">
              <span class="nav-icon">
                <i>
                  <font-awesome-icon :icon="['fas', 'store-alt']" />
                </i>
              </span>
              <span class="nav-text">Link company</span>
            </router-link>
          </li>

          <li class="nav-item mr-14 mt-4">
            <router-link :to="{ name: '/sunbeds/view/:guid/settings/module' }" class="nav-link font-weight-bold border-2 pb-6 mx-0" data-toggle="tab" active-class="active">
              <span class="nav-icon">
                <i>
                  <font-awesome-icon :icon="['fas', 'cube']" />
                </i>
              </span>
              <span class="nav-text">Module</span>
            </router-link>
          </li>

          <li class="nav-item mr-14 mt-4">
            <router-link :to="{ name: '/sunbeds/view/:guid/settings/options' }" class="nav-link font-weight-bold border-2 pb-6 mx-0" data-toggle="tab" active-class="active">
              <span class="nav-icon">
                <i>
                  <font-awesome-icon :icon="['fas', 'sliders-h-square']" />
                </i>
              </span>
              <span class="nav-text">Actions</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="card-body px-0 px-lg-9">
        <router-view
          ref="router-view"
          :equipment="equipment"
          :device="device"
        />
      </div>
    </div>
  </div>
</template>

<script>
import equipmentMixin from '@/components/pages/sunbeds/view/libs/mixin';

export default {
  mixins: [equipmentMixin],
};
</script>
